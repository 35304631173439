<template>
<div>
 <Header></Header>
    <!--顶部banner开始-->
    <div class="aboutus-banner left"> <img src="~images/banner/xinwenzhongxin.jpg" width="100%" /> </div>

    <!--内容展示开始-->
    <div class="news-detail-content left clearfix">
            <div class="news-detail-contents center clearfix">
                <h2 class="news-detail-title">{{data.title}}</h2>
                <p class="news-detail-ftitle">发布时间：{{data.add_time}}  <label id="news-detail-liulan">浏览量：{{data.click_num}} 次</label></p>
                <div class="news-detail-text" v-html="data.content"> {{data.content}} </div>
            </div>
    </div>
    
   <div class="left clearfix width-bai"> <Footer></Footer> </div>
</div>
</template>
<script>
import Header from '../layout/Header.vue'
import Footer from '../layout/Footer.vue'
export default {
   name:"",
   data() {
      return {
          data:''
      }
   },
    components:{
    Header,
    Footer
  },

mounted() {
  var artId=this.$route.query.art_id;

  this.$axios.post(window.configs.svcProt + "://" + window.configs.svcIpPort + '/get_news_detail.html?token='+this.$store.state.token+'&id='+artId)
	.then(res=>{
     this.data = res.data.result;
     document.title=res.data.result.title+'-北京华创龙达建设工程有限公司';
  });
},
}
</script>

<style scoped>
    .news-detail-text{font-size:16px;line-height:35px;padding:20px;}
    #news-detail-liulan{margin-left:20px;}
    .news-detail-ftitle{text-align:center;line-height:50px;color:#999;font-size:14px;}
    .news-detail-title{line-height:60px;border-bottom:1px solid #eee;text-align:center;margin-top:30px;}
    .news-detail-contents{width:1440px;min-height:800px;}
    .news-detail-content{width:100%;min-height:800px;}
</style>
